import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import SimpleLayout from "../components/SimpleLayout/SimpleLayout"
import { Link, graphql } from 'gatsby'

import 'typeface-playfair-display'
import 'typeface-montserrat'
import 'typeface-cormorant-garamond'

import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"

import { css } from "@emotion/react"

const cssContainer = css`
  column-count: 2;
  column-gap: 3%;
  column-width: 400px;

  a {
    text-decoration: none;
  }
`

const cssBlogPostCard = css`
  width: 100%;
  'break-inside': avoid;
  padding: 20px;
  margin-bottom: 35px;
`

const cssTitle = css`
  margin: 0.5rem 0.0rem;
  font: 200 22px montserrat, sans-serif;
`

const cssTitleContainer = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const cssExcerpt = css`
  font: 400 18px cormorant garamond, sans-serif;
  color: #2e353f;
`

const cssDate = css`
  font: 200 12px montserrat, sans-serif;
  color: #2e353f;
`

const cssMedia = css`
  height: 140px;
`

class Blog extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <SimpleLayout>
        <Helmet title={siteTitle} />
        <div css={cssContainer}>
          {posts.map(({ node }) => {
            const title = get(node, 'frontmatter.title') || node.fields.slug
            const date = new Date(get(node, 'frontmatter.date'))
            const link = `/${node.frontmatter.date}${node.fields.slug}`
            const excerpt = node.excerpt

            return (
              <Card key={title} css={cssBlogPostCard}>
                <Link to={link}>
                  { node.frontmatter.image?
                    <CardMedia
                      css={cssMedia}
                      image={node.frontmatter.image.childImageSharp.fluid.src}
                      title={title}
                    />
                    : null
                  }
                  <CardContent>
                    <div css={cssTitleContainer}>
                      <Typography variant="h5" component="h2" css={cssTitle}>
                        {title}
                      </Typography>
                      <Typography component="p" css={cssDate}>
                        {node.frontmatter.date}
                      </Typography>
                    </div>
                    <Typography component="p" css={cssExcerpt}>
                      {excerpt}
                    </Typography>
                  </CardContent>
                </Link>
              </Card>
            )
          })}
        </div>
      </SimpleLayout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            title
            date
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
